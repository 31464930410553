<template>
  <section
    class="container px-3 pt-4 pb-16 mx-auto sm:px-0"
    :class="[
      !(props.selectedUni || props.selectedTag) ? 'lg:grid lg:grid-cols-2' : '',
    ]"
  >
    <template v-if="Object.keys(renderCourses).length !== 0">
      <div
        class="pt-4 pb-4"
        :class="[
          uni === 'Singapore Institute of Technology'
            ? 'lg:col-start-1 lg:row-start-2 lg:row-span-4'
            : '',
        ]"
        v-for="(faculties, uni) in renderCourses"
        :key="uni"
      >
        <h2
          class="
            relative
            mb-3
            font-bold
            uppercase
            sm:text-lg
            text-blue-gray-800
          "
        >
          {{ uni }}
          <div
            class="h-0.5 transform w-full lg:w-11/12 absolute -bottom-2 -left-1"
            :class="{
              'bg-red-800': uni === 'National University of Singapore',
              'bg-sky-800': uni === 'Nanyang Technological University',
              'bg-green-800': uni === 'Singapore Management University',
              'bg-blue-600':
                uni === 'Singapore University of Technology and Design',
              'bg-purple-800': uni === 'Singapore Institute of Technology',
              'bg-pink-800': uni === 'Singapore University of Social Sciences',
            }"
          />
        </h2>
        <template v-for="(courseIDs, faculty) in faculties" :key="faculty">
          <h3 class="mt-8 mb-3 ml-1 text-lg font-medium">{{ faculty }}</h3>
          <ul class="ml-4">
            <li
              v-for="id in courseIDs"
              :key="'course' + id"
              class="pl-1.5 mb-6 border-l-2"
              :class="{
                'border-red-500': uni === 'National University of Singapore',
                'border-sky-500': uni === 'Nanyang Technological University',
                'border-green-500': uni === 'Singapore Management University',
                'border-blue-500':
                  uni === 'Singapore University of Technology and Design',
                'border-purple-500':
                  uni === 'Singapore Institute of Technology',
                'border-pink-500':
                  uni === 'Singapore University of Social Sciences',
              }"
            >
              <router-link
                :to="{ path: `/courses/${id}` }"
                class="text-blue-900 sm:hover:underline"
              >
                {{ courses[id].major }}
              </router-link>
            </li>
          </ul>
        </template>
      </div>
    </template>
    <div
      v-else
      class="flex items-center justify-center gap-1 mt-12 text-blue-gray-800"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="w-7 h-7"
        fill="currentColor"
        viewBox="0 0 256 256"
      >
        <rect width="256" height="256" fill="none"></rect>
        <circle
          cx="128"
          cy="128"
          r="96"
          fill="none"
          stroke="currentColor"
          stroke-width="24"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></circle>
        <line
          x1="128"
          y1="80"
          x2="128"
          y2="132"
          fill="none"
          stroke="currentColor"
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="24"
        ></line>
        <circle cx="128" cy="172" r="16"></circle>
      </svg>
      <p class="text-xl font-bold text-center">
        No courses fit search criteria
      </p>
    </div>
  </section>
</template>

<script setup>
import { computed } from "vue";
import sortedCourses from "@/data/sortedCourses.json";
import courses from "@/data/courses.json";
import coursesFull from "@/data/coursesFull.json";

const props = defineProps({
  selectedUni: {
    type: String,
  },
  selectedTag: {
    type: String,
  },
});

const renderCourses = computed(() => {
  // If there is a selected uni, we'll only take one key value pair.
  const renderCourses = props.selectedUni
    ? ((obj) => {
        obj[props.selectedUni] = sortedCourses[props.selectedUni];
        return obj;
      })({})
    : sortedCourses;

  // If no selected tag, return as is.
  if (!props.selectedTag) return renderCourses;

  // If there is a selected tag, we'll filter out those tags.
  return Object.fromEntries(
    Object.entries(renderCourses)
      // [_, uniCourses] could be ["National ...", { "School of ...": [1, 2, ...] }]
      .map(([_, uniCourses]) => {
        return [
          _,
          Object.fromEntries(
            Object.entries(uniCourses)
              .map(([_, facultyCourses]) => {
                // Remove all courses that does not contain the tag
                return [
                  _,
                  facultyCourses.filter((id) =>
                    coursesFull[id].tags.includes(props.selectedTag)
                  ),
                ];
              })
              .filter((facultyEntry) => {
                // Remove all faculties with no courses that contain tag
                return facultyEntry[1].length;
              })
          ),
        ];
      })
      .filter((uniEntry) => {
        // Remove all unis with no faculties that contain tag
        return Object.keys(uniEntry[1]).length;
      })
  );
});
</script>
