<template>
  <main>
    <!-- Courses Header -->
    <the-heading background-image="graduation">
      <template #header>
        <p
          class="
            mb-2
            text-sm
            tracking-wider
            md:text-base
            xl:text-lg
            text-blue-gray-50
          "
        >
          SGUniStats | Courses
        </p>
        <h1
          class="
            text-2xl
            font-semibold
            leading-7
            md:text-3xl
            xl:text-4xl
            text-blue-gray-50
          "
        >
          Get the full list of statistics and information for each&nbsp;course
        </h1>
      </template>
    </the-heading>

    <!-- Course Search -->
    <section
      class="container px-3 pt-4 mx-auto sm:px-0 sm:pt-8"
      :class="[$route.params.id ? 'pb-16' : 'pb-8']"
    >
      <div class="flex items-center gap-2 mb-1 text-blue-gray-800">
        <h2 class="text-2xl font-bold text-blue-gray-800">Course Search</h2>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
          fill="currentColor"
          viewBox="0 0 256 256"
        >
          <rect width="256" height="256" fill="none"></rect>
          <line
            x1="88"
            y1="64"
            x2="216"
            y2="64"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <line
            x1="88.00614"
            y1="128"
            x2="216"
            y2="128"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <line
            x1="88.00614"
            y1="192"
            x2="216"
            y2="192"
            fill="none"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="24"
          ></line>
          <circle cx="44" cy="128" r="16"></circle>
          <circle cx="44" cy="64" r="16"></circle>
          <circle cx="44" cy="192" r="16"></circle>
        </svg>
      </div>
      <h3 class="mb-6 font-medium leading-5">
        Search for a course from any local university for more info
      </h3>

      <multiselect
        v-model="selectedID"
        :options="selectOptions"
        @change="updateDashboard"
        valueProp="id"
        trackBy="searchTerms"
        :searchable="true"
        placeholder="Search for any course"
        noResultsText="No courses with available data found."
        class="mb-4"
        :classes="{
          container:
            'transition-shadow duration-200 relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border-blue-gray-300 rounded bg-white text-base leading-snug outline-none',
          containerDisabled: 'cursor-default bg-blue-gray-100',
          containerOpen: 'rounded-b-none',
          containerOpenTop: 'rounded-t-none',
          containerActive: 'ring ring-blue-500-opacity-30',
          singleLabel:
            'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
          multipleLabel:
            'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
          search:
            'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
          placeholder:
            'flex text-sm sm:text-base items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-blue-gray-600',
          caret:
            'bg-multiselect-caret bg-center bg-no-repeat w-4 h-6 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
          caretOpen: 'rotate-180 pointer-events-auto',
          clear:
            'mr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex sm:hover:opacity-80',
          clearIcon:
            'bg-multiselect-remove bg-center bg-no-repeat w-4 h-6 py-px box-content inline-block',
          dropdown:
            'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-blue-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
          dropdownHidden: 'hidden',
          options: 'flex flex-col p-0 m-0 list-none',
          optionsTop: 'flex-col-reverse',
          option:
            'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
          optionPointed: 'text-blue-gray-800 bg-blue-gray-100',
          optionSelected: 'text-white bg-blue-500',
          optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
          noOptions: 'py-2 px-3 text-blue-gray-600 bg-white',
          noResults: 'py-2 px-3 text-blue-gray-600 bg-white',
          spacer: 'h-9 py-px box-content',
        }"
        style="caret-color: #0284c7"
      >
        <template #placeholder>
          <div
            class="
              flex
              items-center
              h-full
              absolute
              left-0
              top-0
              pointer-events-none
              bg-transparent
              leading-snug
              pl-2.5
              text-blue-gray-600
              gap-1
            "
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-5 h-5"
              fill="currentColor"
              viewBox="0 0 256 256"
            >
              <rect width="256" height="256" fill="none"></rect>
              <circle
                cx="115.99707"
                cy="116"
                r="84"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></circle>
              <line
                x1="175.39063"
                y1="175.40039"
                x2="223.99121"
                y2="224.00098"
                fill="none"
                stroke="currentColor"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="24"
              ></line>
            </svg>
            <p class="text-sm sm:text-base">Search for any course</p>
          </div>
        </template>

        <template #singlelabel="{ value }">
          <p
            class="
              flex
              items-center
              h-full
              absolute
              left-0
              top-0
              pointer-events-none
              bg-transparent
              leading-snug
              pl-3.5
            "
          >
            <span>
              {{ value.major }}
              <span
                class="text-xs font-medium"
                :class="{
                  'text-red-700': value.university === 'NUS',
                  'text-sky-700': value.university === 'SMU',
                  'text-green-700': value.university === 'NTU',
                  'text-blue-700': value.university === 'SUTD',
                  'text-purple-700': value.university === 'SIT',
                  'text-pink-700': value.university === 'SUSS',
                }"
              >
                ({{ value.university }})
              </span>
            </span>
          </p>
        </template>

        <template #option="{ option }">
          <span class="mr-1 text-sm">{{ option.major }}</span>
          <span
            class="text-xs font-medium"
            :class="{
              'text-red-700': option.university === 'NUS',
              'text-sky-700': option.university === 'SMU',
              'text-green-700': option.university === 'NTU',
              'text-blue-700': option.university === 'SUTD',
              'text-purple-700': option.university === 'SIT',
              'text-pink-700': option.university === 'SUSS',
            }"
            v-if="option.university"
            >({{ option.university }})</span
          >
        </template>
      </multiselect>
    </section>

    <!-- Dashboard -->
    <router-view :key="$route.params.id" />

    <app-hr v-if="!$route.params.id" />

    <!-- Course Directory -->
    <section
      class="container px-3 mx-auto sm:px-0"
      :class="[$route.params.id ? 'pt-16' : 'pt-8']"
    >
      <h2 class="mb-1 text-2xl font-bold text-blue-gray-800">
        Course Directory
      </h2>
      <h3 class="mb-6 font-medium leading-5">
        Learn more about each course from the GES data to the IGP
      </h3>

      <div class="flex gap-4 mb-3">
        <!-- Select University Filter -->
        <multiselect
          v-model="selectedUni"
          :options="selectUnis"
          mode="single"
          placeholder="University"
          :classes="{
            container:
              'transition-shadow duration-200 relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border border-blue-gray-300 rounded bg-white text-base font-sans leading-snug outline-none',
            containerOpen: 'rounded-b-none',
            containerActive: 'ring ring-blue-500 ring-opacity-30',
            singleLabel:
              'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
            search:
              'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
            placeholder:
              'flex text-xs sm:text-base items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-blue-gray-600',
            caret:
              'bg-multiselect-caret bg-center bg-no-repeat w-4 h-6 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
            caretOpen: 'rotate-180 pointer-events-auto',
            clear:
              'mr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex sm:hover:opacity-80',
            clearIcon:
              'bg-multiselect-remove bg-center bg-no-repeat w-4 h-6 py-px box-content inline-block',
            dropdown:
              'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-blue-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
            dropdownHidden: 'hidden',
            options: 'flex flex-col p-0 m-0 list-none',
            option:
              'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
            optionPointed: 'text-blue-gray-800 bg-blue-gray-100',
            optionSelected: 'text-white bg-blue-500',
            optionDisabled: 'text-blue-gray-300 cursor-not-allowed',
            optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
            noOptions: 'py-2 px-3 text-blue-gray-600 bg-white',
            noResults: 'py-2 px-3 text-blue-gray-600 bg-white',
            spacer: 'h-9 py-px box-content',
          }"
        />
        <!-- Select Course Tag Filter -->
        <multiselect
          v-model="selectedTag"
          :options="selectTags"
          mode="single"
          placeholder="Course Type"
          :classes="{
            container:
              'transition-shadow duration-200 transition-shadow duration-200 relative mx-auto w-full flex items-center justify-end box-border cursor-pointer border border border-blue-gray-300 rounded bg-white text-base font-sans leading-snug outline-none',
            containerOpen: 'rounded-b-none',
            containerActive: 'ring ring-blue-500 ring-opacity-30',
            singleLabel:
              'flex items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5',
            search:
              'w-full absolute inset-0 outline-none appearance-none box-border border-0 text-base font-sans bg-white rounded pl-3.5',
            placeholder:
              'flex text-xs sm:text-base items-center h-full absolute left-0 top-0 pointer-events-none bg-transparent leading-snug pl-3.5 text-blue-gray-600',
            caret:
              'bg-multiselect-caret bg-center bg-no-repeat w-4 h-6 py-px box-content mr-3.5 relative z-10 opacity-40 flex-shrink-0 flex-grow-0 transition-transform transform pointer-events-none',
            caretOpen: 'rotate-180 pointer-events-auto',
            clear:
              'pr-3.5 relative z-10 opacity-40 transition duration-300 flex-shrink-0 flex-grow-0 flex sm:hover:opacity-80',
            clearIcon:
              'bg-multiselect-remove bg-center bg-no-repeat w-4 h-6 py-px box-content inline-block',
            dropdown:
              'max-h-60 absolute -left-px -right-px bottom-0 transform translate-y-full border border-blue-gray-300 -mt-px overflow-y-scroll z-50 bg-white flex flex-col rounded-b',
            dropdownHidden: 'hidden',
            options: 'flex flex-col p-0 m-0 list-none',
            option:
              'flex items-center justify-start box-border text-left cursor-pointer text-base leading-snug py-2 px-3',
            optionPointed: 'text-blue-gray-800 bg-blue-gray-100',
            optionSelected: 'text-white bg-blue-500',
            optionDisabled: 'text-blue-gray-300 cursor-not-allowed',
            optionSelectedPointed: 'text-white bg-blue-500 opacity-90',
            noOptions: 'py-2 px-3 text-blue-gray-600 bg-white',
            noResults: 'py-2 px-3 text-blue-gray-600 bg-white',
            spacer: 'h-9 py-px box-content',
          }"
        >
          <template #singlelabel="{ value }">
            <div
              class="
                flex
                items-center
                h-full
                absolute
                left-0
                top-0
                pointer-events-none
                bg-transparent
                leading-snug
                pl-3.5
                gap-1
              "
            >
              <app-icon :tag="value.value" :size="5" weight="regular" />
              <p class="text-sm sm:text-base">
                {{ value.label }}
              </p>
            </div>
          </template>

          <template #option="{ option }">
            <div class="flex items-center gap-1">
              <app-icon :tag="option.value" :size="5" weight="regular" />
              <p>{{ option.label }}</p>
            </div>
          </template>
        </multiselect>
      </div>

      <p class="mb-5 text-blue-gray-600">
        Click on any of the courses to learn more.
      </p>
    </section>

    <app-course-directory
      :selectedUni="selectedUni"
      :selected-tag="selectedTag"
    />

    <app-hr />

    <app-cards :hiddenCards="['courses']" desc="More Tools" />

    <app-hr />

    <app-notes page="courses" />
  </main>
</template>

<script setup>
import { ref, nextTick } from "vue";
import { useRouter } from "vue-router";

import Multiselect from "@vueform/multiselect";
import TheHeading from "@/components/TheHeading.vue";
import AppIcon from "@/components/AppIcon.vue";
import AppCourseDirectory from "@/components/AppCourseDirectory.vue";
import AppNotes from "@/components/AppNotes.vue";
import AppCards from "@/components/AppCards.vue";
import AppHr from "@/components/AppHr.vue";

import courses from "@/data/courses.json";
import { notChild, courseTags } from "@/data/courseLists.json";

const selectOptions = notChild
  .map((id) => {
    return {
      id,
      label: `${courses[id].major} (${courses[id].university})`,
      // Combining Major and University for easier search results
      // Repeated as NUS may be searched as the first term
      searchTerms: `${courses[id].major} ${courses[id].university} ${courses[id].major}`,
      major: courses[id].major,
      university: courses[id].university,
    };
  })
  .sort((a, b) => a.major.localeCompare(b.major));

const selectUnis = [
  { value: "National University of Singapore", label: "NUS" },
  { value: "Nanyang Technological University", label: "NTU" },
  { value: "Singapore Management University", label: "SMU" },
  {
    value: "Singapore University of Technology and Design",
    label: "SUTD",
  },
  { value: "Singapore Institute of Technology", label: "SIT" },
  { value: "Singapore University of Social Sciences", label: "SUSS" },
];

const selectTags = courseTags.map((tag) => {
  return {
    value: tag,
    label: tag.charAt(0).toUpperCase() + tag.slice(1),
  };
});

const selectedUni = ref(null);
const selectedTag = ref(null);
const selectedID = ref(null);

const router = useRouter();

const updateDashboard = () => {
  nextTick(() => {
    if (selectedID.value) router.push({ path: `/courses/${selectedID.value}` });
  });
};
</script>
